import React from 'react';
import styled from 'styled-components';
import MyContainer from "../containers/MyContainer";
import {useLanguageHook} from "../../hooks/useLanguageHook";

export default function StatsSection(props) {
    const intl = useLanguageHook();


    const ITEMS = [
        {
            title: '85%',
            text: intl.formatMessage({id: 'section.stats.item1.text'}),
            color: '#A940EA'
        },
        {
            title: '+16%',
            text: intl.formatMessage({id: 'section.stats.item2.text'}),
            color: '#06CDD8'
        },
        {
            title: '+26%',
            text: intl.formatMessage({id: 'section.stats.item3.text'}),
            color: '#FC004C'
        },
        {
            title: '+21%',
            text: intl.formatMessage({id: 'section.stats.item4.text'}),
            color: '#097A22'
        },
        {
            title: '+18%',
            text: intl.formatMessage({id: 'section.stats.item5.text'}),
            color: '#FFAC30'
        }
    ];

    return (
        <Wrapper>

            <MContainer>

                <Cont>

                    <BoxesPlaceholder>
                        {ITEMS.map((it, j) => {
                            return (
                                <Box key={j} {...it} >
                                    <Name>
                                        {it.title}
                                    </Name>
                                    <Description>
                                        {it.text}
                                    </Description>
                                </Box>
                            )
                        })}
                    </BoxesPlaceholder>

                </Cont>

            </MContainer>

            <TStrIm src={require('../../images/st_str.svg')}/>

        </Wrapper>
    );
}

const MContainer = styled.div`
  width: 1100px;
  box-sizing: border-box;
  margin: 0 auto;
`;


const Wrapper = styled.div`
  color: white;
  padding-bottom: 30px;
  position: relative;
  overflow-x: auto;
  max-width: 100vw;
  overflow-x: auto;
  margin-top: 70px;
`;

const TStrIm = styled.img`
  position: absolute;
  left: 30px;
  width: 120px;
  height: 279px;
  bottom: -6px;
  @media (max-width: 1400px) {
    display: none;
  }
`;


const Cont = styled.div`
  padding-top: 48px;
`;

const BoxesPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

const Box = styled.div`
  box-shadow: 0px 30px 60px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  padding: 20px;
  padding-top: 98px;
  //padding-bottom: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  margin: 8px;
  width: calc(20% - 16px);
  height: 384px;

  background-color: ${props => props.color};

  @media(max-width: 920px){
    height: 324px;
    padding-top: 48px;
  }
  
  //@media (max-width: 820px) {
  //  width: calc(50% - 20px);
  //}
  //@media (max-width: 520px) {
  //  width: calc(100% - 20px);
  //}

`;

const Name = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 64px;
  line-height: 48px;
  letter-spacing: -1.25px;
  margin-bottom: 112px;

  @media (max-width: 1020px) {
    font-size: 52px;
  }

  @media (max-width: 820px) {
    font-size: 44px;
  }

`;

const Description = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.2px;
  color: #FFFFFF;
`;



import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';

import {Set} from 'immutable'

import styled from 'styled-components';
import {Analytics} from "../../services/analytics";
import {useLanguageHook} from "../../hooks/useLanguageHook";
const crossImg = require('../../images/red_red_close.svg');
const checkImg = require('../../images/white_check.svg');
const downChevronImg = require('../../images/down-chevron.svg');


export default function MobilePricingBlock(props) {
    const { mode = 'month'} = props;
    const [expandedSet, setExpandedSet] = useState(Set());
    const intl = useLanguageHook();
    let items = (mode == 'month') ? ITEMS.filter(x => (x.isYear != true)) : ITEMS.filter(x => (x.isFree == true || x.isYear == true));
    return (
        <Wrapper>

            {items.map((a, i) => {
                let {name, price} = a;
                let isExpanded = expandedSet.has(a.id);
                return (
                    <ItemCont isGold={(i == 2)} expanded={isExpanded} key={`x_${i}`}>
                        <ItemNamePlaceholder onClick={() => {
                            if (isExpanded == true) {
                                setExpandedSet(expandedSet.delete(a.id));
                            } else {
                                setExpandedSet(expandedSet.add(a.id));
                            }
                        }}>
                            <ItemName>
                                {name}
                            </ItemName>
                            <ItemPriceTopSection>
                                <GoldPrice expanded={i == 2}>
                                    {`$${price}`}
                                </GoldPrice>
                                <ItemPriceSpan>
                                    {`/${intl.formatMessage({id: `section.pricing.${mode}`})}`}
                                </ItemPriceSpan>
                            </ItemPriceTopSection>

                            <ChevronPlaceholder>
                                <ChevronImg src={downChevronImg}
                                            style={{transform: `rotate(${isExpanded == true ? 180 : 0}deg)`}}
                                />
                            </ChevronPlaceholder>

                        </ItemNamePlaceholder>

                        {isExpanded == false ? null :
                            <ExpandedContentPlaceholder>
                                <ExpandedRow>
                                    <span>
                                        { intl.formatMessage({id: 'section.pricing.feature1'})}
                                    </span>
                                    <span>
                                        {a.video_num}
                                    </span>
                                </ExpandedRow>

                                {
                                    [
                                        intl.formatMessage({id: 'section.pricing.feature2'}),
                                        intl.formatMessage({id: 'section.pricing.feature3'}),
                                        intl.formatMessage({id: 'section.pricing.feature4'}),
                                        intl.formatMessage({id: 'section.pricing.feature5'}),
                                        intl.formatMessage({id: 'section.pricing.feature6'}),
                                        intl.formatMessage({id: 'section.pricing.feature7'}),
                                        intl.formatMessage({id: 'section.pricing.feature9'}),
                                        intl.formatMessage({id: 'section.pricing.feature10'}),
                                        {
                                            label: intl.formatMessage({id: 'section.pricing.feature8'}),
                                            valueFunction: z => {
                                                if (z == 0) {
                                                    return false;
                                                }
                                                return true;
                                            }
                                        },
                                    ].map((x, j) => {
                                        let s = (typeof x == 'string') ? x : x.label;
                                        let chImg = (typeof x != 'string' && x.valueFunction(i) == false) ? crossImg : checkImg;
                                        return (
                                            <ExpandedRow key={j}>
                                                <span>
                                                    {s}
                                                </span>
                                                <CheckImg src={chImg}/>
                                            </ExpandedRow>
                                        )
                                    })
                                }

                            </ExpandedContentPlaceholder>
                        }

                        <div onClick={() => {
                            try {
                                try {
                                    Analytics.send('lead.account.start', 'lead');
                                    window.ga('set', 'dimension1', it.name.toLowerCase());
                                } catch (qwqw) {

                                }
                                window.localStorage.setItem('auth_mode', 'signup');
                                window.location.href = `https://my.subtitles.love/?signup`;
                            } catch (exc) {

                            }
                        }}>
                            {isExpanded == true ?
                                <GetStartedButton isGold={i == 2}>
                                    { intl.formatMessage({id: 'section.top.start'})}
                                </GetStartedButton>
                                :
                                <ChoseButton isGold={i == 2}>
                                    { intl.formatMessage({id: 'section.pricing.subscribe'})}
                                </ChoseButton>
                            }
                        </div>


                    </ItemCont>
                )
            })}

        </Wrapper>
    );
}


const ExpandedContentPlaceholder = styled.div`

`;

const ChoseButton = styled.div`
  border: ${props => (props.isGold == true ? 'none' : '2px solid #01939B')};
  box-sizing: border-box;
  border-radius: 5px;
  height: 44px;

  background: ${props => (props.isGold == true ? 'white' : 'transparent')};
  color: ${props => (props.isGold == true ? '#171822' : '#01939B')};

  font-weight: bold;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const GetStartedButton = styled.div`
  box-sizing: border-box;
  border-radius: 5px;
  height: 44px;
  background: ${props => (props.isGold == true ? 'white' : '#01939B')};
  color: ${props => (props.isGold == true ? '#171822' : 'white')};
  font-weight: bold;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const ExpandedRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-top: 20px;
  padding-bottom: 20px;
`;

const ChevronImg = styled.img`
  height: 12px;
`;

const ChevronPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 120px;
`;

const ItemPriceSpan = styled.span`
  color: #848199;
  margin-left: 5px;
`;

const ItemCont = styled.div`
  margin-bottom: 40px;
  background: ${props => (props.isGold == true ? '#FFAC30' : 'transparent')};
  padding: ${props => (props.isGold == true ? '22px' : '0px')};
  border-radius: ${props => (props.isGold == true ? '12px' : '0px')};
`;

const GoldPrice = styled.div`
  color: ${props => (props.expanded == true ? '#171822' : '#FFAC30')};
  font-size: 36px;
  line-height: 46px;
  font-weight: 600;
`;

const ItemNamePlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  :hover {
    opacity: 0.8;
  }
`;

const ItemName = styled.div`
  font-family: Avenir;
  font-style: normal;
  font-weight: 900;
  font-size: 28px;
  line-height: 38px;
  display: flex;
  align-items: center;
  color: #FFFFFF;
  flex: 1;
`;

const ItemPriceTopSection = styled.div`
  margin-right: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 10px;
`;

const Wrapper = styled.div`
  color: white;
  max-width: 1100px;
  box-sizing: border-box;
  padding: 10px;
  margin: 50px auto auto;
`;

const TopRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const CheckImg = styled.img`
  width: 18px;
`;

const RowColumn = styled.div`
  flex: 1;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  background: ${props => props.background};
`;

const RowColumn2 = styled(RowColumn)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 45px;
  background: ${props => props.background};
  padding-bottom: 10px;
`;

const RowLabel = styled.div`
  color: white;
  font-weight: bold;
  font-size: 28px;
  text-align: center;
  margin-bottom: 20px;
`;

const RowActionPlaceholder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const RowPrice = styled.div`
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const RowPriceSpan = styled.div`
  color: ${props => (props.color == undefined ? '#FFAC30' : props.color)};
  font-size: 32px;
  font-weight: bold;
`;

const RowPriceMonthSpan = styled.div`
  color: #848199;
  font-size: 17px;
  margin-left: 3px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const RowLeftColumn = styled.div`
  width: 200px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const DesktopPlaceholder = styled.div`
  @media (max-width: 800px) {
    display: none;
  }
`;

const MobilePlaceholder = styled.div`
  display: none;
  @media (max-width: 800px) {
    display: block;
  }
`;

const Cont = styled.div`
  padding-top: 48px;
  width: 920px;
  margin: 0 auto;
  box-sizing: border-box;
  @media (max-width: 920px) {
    width: 100%;
  }
`;

const BoxesPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

const Box = styled.div`
  position: relative;
  padding: 25px;
  box-sizing: border-box;
  margin: 15px;
  width: calc(33.33% - 30px);
  border: 2px solid rgba(255, 255, 255, 0.03);
  border-radius: 16px;
  background: ${props => props.background};
  @media (max-width: 820px) {
    width: calc(50% - 30px);
  }
  @media (max-width: 520px) {
    width: calc(100% - 30px);
  }
`;

const Name = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 48px;
  text-align: center;
  letter-spacing: -1.25px;
  color: ${props => props.color};
`;

const Motto = styled.div`
  font-size: 32px;
  line-height: 48px;
  text-align: center;
  letter-spacing: -1.25px;
  color: ${props => props.color};
  margin-top: 10px;
  font-style: italic;
  font-weight: bold;
`;

const SubMotto = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  letter-spacing: 2.5px;
  color: ${props => props.color};
  margin-top: 6px;
`;

const CenterText = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 32px;
  text-align: center;
  letter-spacing: -0.25px;
  color: ${props => props.color};
  margin-top: 40px;
`;

const BottomText = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.25px;
  color: ${props => props.color};
  margin-top: 40px;
`;

const ButtonPlaceholder = styled.div`
  margin-top: 24px;
`;

const Button = styled.div`
  background: ${props => props.buttonColor};
  color: ${props => props.buttonTextColor};

  border-radius: 5px;
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 17px;
  height: 44px;
  font-weight: bold;
  cursor: pointer;

  :hover {
    opacity: 0.8;
  }
`;

const MyBox = styled.div`
  background: transparent;
  color: ${props => props.buttonTextColor};
  border: 2px solid white;
  border-radius: 5px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 17px;
  height: 44px;
  font-weight: bold;
  box-sizing: border-box;
`;

const FEATURES = [
    {
        name: 'video_num',
        label: 'Video',
        isText: true
    },
    {
        name: 'automatic_captions',
        label: 'Automatic Captions'
    },
    {
        name: 'timing_editor',
        label: 'Caption Text/Timing Editor'
    },
    {
        name: 'languages_20',
        label: '20+ Supported Languages'
    },
    {
        name: 'style_editor',
        label: 'Simple Style editor'
    },
    {
        name: 'video_resizing',
        label: 'Video Resizing'
    },
    {
        name: 'translator',
        label: 'Subtitles translator'
    },
    {
        name: 'no_watermark',
        label: 'No watermark'
    },
    {
        name: 'srt',
        label: 'Download .SRT File'
    },
    {
        name: 'original_size',
        label: 'Original video  size'
    },
    {
        name: 'faster_rendering',
        label: 'Faster rendering'
    },
    {
        name: 'export_1080p',
        label: 'Export Quality 1080'
    },
    {
        name: 'max_duration',
        label: 'Video duration',
        isText: true
    },
    {
        name: 'max_file',
        label: 'Video size',
        isText: true
    }
];

const ITEMS = [
    {
        id: '8oCk3S8ZJZ',
        paddle_id: '586033',

        isFree: true,

        video_num: '1 video',

        translator: false,
        no_watermark: false,
        automatic_captions: true,
        timing_editor: true,
        languages_20: true,
        style_editor: true,

        video_resizing: false,
        srt: false,
        original_size: false,
        faster_rendering: false,
        export_1080p: false,
        video_length: '20 min',
        max_file_size: '500mb',

        // background: '#212330',
        background: 'transparent',
        price: 0,
        name: 'Free',
        nameColor: '#FFFFFF',
        motto: 'Free',
        mottoColor: '#5C5C5C',
        subMotto: 'FOREVER',
        subMottoColor: '#5C5C5C',
        centerText: '1 video / 1 render',
        centerColor: '#EB5E4F',

        text: `
            <div class="feature_item" >
                Automatic Captions
            </div>
            <div class="feature_item" >
                Caption Text/Timing Editor
            </div>
            <div class="feature_item" >
                12+ Supported Languages
            </div>
            <div class="feature_item" >
                Simple Style editor
            </div>
            <div class="feature_item" >
                Video Resizing
            </div>
            <div class="feature_item" >
                Watermark
            </div>
        `,
        textColor: '#FFFCFC',

        buttonColor: '#01939B',
        buttonTextColor: '#FFFFFF',
        buttonText: 'SUBSCRIBE'
    },

    {

        id: 'zdCnvJmPIo',
        paddle_id: '633614',

        price: 5,

        video_num: '1 video',

        translator: false,
        no_watermark: true,
        automatic_captions: true,
        timing_editor: true,
        languages_20: true,
        style_editor: true,
        video_resizing: true,
        srt: false,
        original_size: true,
        faster_rendering: true,
        export_1080p: true,
        video_length: '30 min',
        max_file_size: '800mb',

        // background: '#212330',
        background: 'transparent',

        name: 'Light',
        nameColor: '#FFFFFF',
        motto: '$5',
        mottoColor: '#5C5C5C',
        subMotto: 'PER MONTH',
        subMottoColor: '#5C5C5C',
        centerText: '1 video / 5 renders',
        centerColor: '#EB5E4F',

        text: `
            <div class="feature_item" >
                Automatic Captions
            </div>
            <div class="feature_item" >
                Caption Text/Timing Editor
            </div>
            <div class="feature_item" >
                12+ Supported Languages
            </div>
            <div class="feature_item" >
                Advanced Style editor
            </div>
            <div class="feature_item" >
                Video Resizing
            </div>
            <div class="feature_item" >
                Download .SRT File
            </div>
            <div class="feature_item" >
                No watermark
            </div>
        `,
        textColor: '#FFFCFC',

        buttonColor: '#01939B',
        buttonTextColor: '#FFFFFF',
        buttonText: 'SUBSCRIBE'
    },

    {


        id: 'EEySyPbAeu',
        paddle_id: '586001',

        price: 13,

        video_num: '15 videos',

        translator: true,
        no_watermark: true,
        automatic_captions: true,
        timing_editor: true,
        languages_20: true,
        style_editor: true,
        video_resizing: true,
        srt: true,
        original_size: true,
        faster_rendering: true,
        export_1080p: true,
        video_length: '40 min',
        max_file_size: '1.5GB',

        // background: '#212330',
        // background: 'transparent',
        background: '#FFAC30',

        priceColor: '#212330',
        nameColor: '#212330',

        name: 'Medium',
        // nameColor: '#FFFFFF',
        motto: '$13',
        mottoColor: '#5C5C5C',
        subMotto: 'PER MONTH',
        subMottoColor: '#5C5C5C',
        centerText: '15 videos / 50 renders',
        centerColor: '#EB5E4F',

        text: `
            <div class="feature_item" >
                Automatic Captions
            </div>
            <div class="feature_item" >
                Caption Text/Timing Editor
            </div>
            <div class="feature_item" >
                12+ Supported Languages
            </div>
            <div class="feature_item" >
                Advanced Style editor
            </div>
            <div class="feature_item" >
                Video Resizing
            </div>
            <div class="feature_item" >
                Download .SRT File
            </div>
            <div class="feature_item" >
                No watermark
            </div>
        `,
        textColor: '#FFFCFC',

        buttonColor: '#FFFFFF',
        buttonTextColor: '#212330',
        buttonText: 'SUBSCRIBE'
    },

    {
        id: '2v7DU1gu8l',
        paddle_id: '586002',

        background: 'transparent',

        // background: '#FFAC30',

        video_num: '75 videos',

        translator: true,
        no_watermark: true,
        automatic_captions: true,
        timing_editor: true,
        languages_20: true,
        style_editor: true,
        video_resizing: true,
        srt: true,
        original_size: true,
        faster_rendering: true,
        export_1080p: true,
        video_length: '60 min',
        max_file_size: '2GB',

        price: 49,

        name: 'Premium',
        // priceColor: '#212330',
        nameColor: '#212330',
        motto: '$49',
        mottoColor: '#FFFFFF',
        subMotto: 'PER MONTH',
        subMottoColor: '#FFFFFF',

        centerText: '75 videos / 300 renders',
        centerColor: '#212330',

        text: `
            <div class="feature_item" >
                Automatic Captions
            </div>
            <div class="feature_item" >
                Caption Text/Timing Editor
            </div>
            <div class="feature_item" >
                12+ Supported Languages
            </div>
            <div class="feature_item" >
                Advanced Style editor
            </div>
            <div class="feature_item" >
                Video Resizing
            </div>
            <div class="feature_item" >
                Faster rendering
            </div>
            <div class="feature_item" >
                Download .SRT File
            </div>
            <div class="feature_item" >
                No watermark
            </div>
        `,
        textColor: '#FFFFFF',

        buttonColor: '#01939B',
        buttonTextColor: '#FFFFFF',
        buttonText: 'SUBSCRIBE'

    },

    {

        isYear: true,

        id: 'opdSjVp2xQ',
        paddle_id: '646321',

        price: 110,

        video_num: '180 videos',

        translator: true,
        no_watermark: true,
        automatic_captions: true,
        timing_editor: true,
        languages_20: true,
        style_editor: true,
        video_resizing: true,
        srt: true,
        original_size: true,
        faster_rendering: true,
        export_1080p: true,
        video_length: '40 min',
        max_file_size: '2GB',

        // background: '#212330',
        // background: 'transparent',
        background: '#FFAC30',

        priceColor: '#212330',
        nameColor: '#212330',

        name: 'Medium',
        // nameColor: '#FFFFFF',
        motto: '$110',
        mottoColor: '#5C5C5C',
        subMotto: 'PER YEAR',
        subMottoColor: '#5C5C5C',
        centerText: '180 videos / 1800 renders',
        centerColor: '#EB5E4F',

        text: `
            <div class="feature_item" >
                Automatic Captions
            </div>
            <div class="feature_item" >
                Caption Text/Timing Editor
            </div>
            <div class="feature_item" >
                12+ Supported Languages
            </div>
            <div class="feature_item" >
                Advanced Style editor
            </div>
            <div class="feature_item" >
                Video Resizing
            </div>
            <div class="feature_item" >
                Download .SRT File
            </div>
            <div class="feature_item" >
                No watermark
            </div>
        `,
        textColor: '#FFFCFC',

        buttonColor: '#FFFFFF',
        buttonTextColor: '#212330',
        buttonText: 'SUBSCRIBE'
    },

    {

        isYear: true,

        id: '27MZgMccfj',
        paddle_id: '646322',

        background: 'transparent',

        // background: '#FFAC30',

        video_num: '900 videos',

        translator: true,
        no_watermark: true,
        automatic_captions: true,
        timing_editor: true,
        languages_20: true,
        style_editor: true,
        video_resizing: true,
        srt: true,
        original_size: true,
        faster_rendering: true,
        export_1080p: true,
        video_length: '60 min',
        max_file_size: '2.5GB',

        price: 410,

        name: 'Premium',
        // priceColor: '#212330',
        nameColor: '#212330',
        motto: '$410',
        mottoColor: '#FFFFFF',
        subMotto: 'PER YEAR',
        subMottoColor: '#FFFFFF',

        centerText: '900 videos / 9000 renders',
        centerColor: '#212330',

        text: `
            <div class="feature_item" >
                Automatic Captions
            </div>
            <div class="feature_item" >
                Caption Text/Timing Editor
            </div>
            <div class="feature_item" >
                20+ Supported Languages
            </div>
            <div class="feature_item" >
                Advanced Style editor
            </div>
            <div class="feature_item" >
                Video Resizing
            </div>
            <div class="feature_item" >
                Faster rendering
            </div>
            <div class="feature_item" >
                Download .SRT File
            </div>
            <div class="feature_item" >
                No watermark
            </div>
        `,
        textColor: '#FFFFFF',

        buttonColor: '#01939B',
        buttonTextColor: '#FFFFFF',
        buttonText: 'SUBSCRIBE'

    },

]


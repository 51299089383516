import React from 'react';
import styled from 'styled-components';
import MyContainer from "../containers/MyContainer";
import CoolVideoPlayer from "../players/CoolVideoPlayer";
import {Analytics} from "../../services/analytics";
import {useLanguageHook} from "../../hooks/useLanguageHook";

export default function TopSection(props) {
    const intl = useLanguageHook();

    return (
        <Wrapper>


            <MyContainer>

                <Row>

                    <Left>
                        <Motto>
                            {`${intl.formatMessage({id: 'section.top.motto1'})} `}
                            <SubSpan>
                                {intl.formatMessage({id: 'section.top.motto2'})}
                            </SubSpan>
                            {` ${intl.formatMessage({id: 'section.top.motto3'})}`}
                        </Motto>
                        <Motto>
                            {intl.formatMessage({id: 'section.top.motto4'})}
                        </Motto>
                        <SubMotto>
                            {intl.formatMessage({id: 'section.top.submotto'})}
                        </SubMotto>

                        <StartButtonPlaceholder>

                            <StartButton onClick={() => {
                                Analytics.send('lead.account.start', 'lead');
                                try {
                                    window.localStorage.setItem('auth_mode', 'signup');
                                    window.location.href = `https://my.subtitles.love?signup`;
                                } catch (exc) {
                                }
                            }}>
                                {intl.formatMessage({id: 'section.top.start'})}
                            </StartButton>

                        </StartButtonPlaceholder>

                    </Left>

                    <Right>
                        <CoolVideoPlayer/>
                    </Right>

                </Row>

            </MyContainer>

            <TopRightStrangeImage src={require('../../images/top_right_s.svg')}/>
            <TopLeftStrangeImage src={require('../../images/top_left_strange_s.svg')}/>


        </Wrapper>
    );
}

const Wrapper = styled.div`
    position: relative;
    width: 100%;
    margin-bottom: 100px;
    
    //background: orangered;
    
`;

const TopRightStrangeImage = styled.img`
    position: absolute;
    top: -120px;
    right: 0px;
    height: 300px;
    @media(max-width: 1400px){
      display: none;
    }
`;

const TopLeftStrangeImage = styled.img`
    position: absolute;
    top: 340px;
    left: 0px;
    height: 175px;
    @media(max-width: 1700px){
      display: none;
    }
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    @media(max-width: 920px){
      display: block;
      text-align: center;
    }
`;

const contW = 1100;

const Left = styled.div`
    flex: 2;
    //background: pink;
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 45%;
`;

const Right = styled.div`
    flex: 3;
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(4% + 50vw);
    box-sizing: border-box;
    
    //background: yellow;
    position: relative;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(4% + 50vw);
    z-index: 10;
    padding-left: 60px;
    @media(max-width: 920px){
      margin-top: 30px;
      padding-left: 0px;  
      flex-grow: unset;
      flex-shrink: unset;
      flex-basis: unset;
      width: 100%;
    }
`;

const Box = styled.div`
    background: #212330;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 410px;
    height: 333px;
    box-shadow: 0px 30px 60px rgba(0, 0, 0, 0.25);
    border-radius: 12px;
`;

const RightImageBox = styled.img`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    //height: 333px;
    //box-shadow: 0px 30px 60px rgba(0, 0, 0, 0.25);
    //border-radius: 12px;
    transform: scale(1.05);
    box-shadow: 0px 64px 60px rgba(0, 0, 0, 0.1), 0px 4px 40px rgba(0, 0, 0, 0.1);
`;

const VidImg = styled.img`
    width: 178px;
    height: 115px;
`;

const UplButton = styled.img`
    height: 50px;
    width: 220px;
    margin-top: 37px;
`;

const DropText = styled.div`
    text-align: center;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 21px;
    color: #FFFFFF;
    margin-top: 21px;
`;

const Motto = styled.h1`
    font-size: 38px;
    line-height: 40px;
    letter-spacing: 2px;
    color: #FFFFFF;
    font-weight: bold;
    @media(max-width: 720px){
      font-size: 38px;
    }
    @media(max-width: 520px){
      font-size: 34px;
    }
`;

const SubSpan = styled.span`
    color: rgb(255, 255, 255);
    background: #EB5E4F;
    padding-left: 4px;
    padding-right: 4px;
`;

const SubMotto = styled.h2`
  font-weight: 500;
  font-size: 14px;
  line-height: 34px;
  color: white;
  margin-top: 15px;
`;

const StartButtonPlaceholder = styled.div`
    margin-top: 30px;
`;

const StartButton = styled.div`
    background: #008F97;
    border-radius: 5px;
    padding-left: 20px;
    padding-right: 20px;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 17px;
    color: white;
    height: 46px;
    font-weight: bold;
    cursor: pointer;
    min-width: 110px;
    :hover{
      opacity: 0.8;
    }
    @media(max-width: 720px){
      font-size: 16px;
      padding-left: 10px;
      padding-right: 10px;
    }
    @media(max-width: 520px){
      font-size: 14px;
      padding-left: 7px;
      padding-right: 7px;
    }
`;

import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';

import './style.css'

const coverImg = require('../../images/mac_upd_2.png');
const videoSrc = require('./subtitles_love_instr.mp4')
const bgVid = require('../../images/bg_vid.png');


export default function CoolVideoPlayer(props) {
    const [isVideo, setIsVideo] = useState(false);

    return (
        <Wrapper>
            {isVideo ?
                <VideoPlaceholder>
                    <VideoTop>
                        <TopControlsPlaceholder>
                            <Circle color={'#F96058'}/>
                            <Circle color={'#FABF2F'}/>
                            <Circle color={'#28CE3C'}/>
                        </TopControlsPlaceholder>
                        <TopText>
                            Subtitles.Love
                        </TopText>
                        <TopControlsPlaceholder>
                            <Circle color={'transparent'}/>
                            <Circle color={'transparent'}/>
                            <Circle color={'transparent'}/>
                        </TopControlsPlaceholder>
                    </VideoTop>
                    <Video controls={true} preload={'auto'} autoPlay={true}>
                        <source src={videoSrc}/>
                    </Video>
                </VideoPlaceholder>
                :
                <>
                <InnerImg src={coverImg}/>
                <Overlay onClick={() => {
                    setIsVideo(true);
                }}>
                    <PlayButton src={require('./round_play.svg')} className={'blob'}/>
                </Overlay>
                </>
            }

        </Wrapper>
    );
}

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
`;

const Video = styled.video`
    width: 100%;
    height: 100%;
`;

const VideoTop = styled.div`
    height: 30px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

const TopText = styled.div`
    color: white;
    font-size: 18px;
`;

const TopControlsPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const Circle = styled.div`
    border-radius: 1000px;
    background: ${props => props.color};
    margin-right: 10px;
    height: 13px;
    width: 13px;
`;

const VideoPlaceholder = styled.div`
    box-sizing: border-box;
    
    background: rgba(33,35,48, 0.5);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 14px;
    
    border-radius: 13px;
    
    //@media(max-height: 569px){
    //  height: calc(100vh - 100px);
    //}
    
`;

const InnerImg = styled.img`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    transform: scale(1.05);
    box-shadow: 0px 64px 60px rgba(0, 0, 0, 0.1), 0px 4px 40px rgba(0, 0, 0, 0.1);
`;

const Overlay = styled.div`
    background: rgba(0, 0, 0, 0.4);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 10;
    top: 23px;
    bottom: 0px;
    left: -3px;
    right: -3px;
    cursor: pointer;
    border-radius: 8px;
    :hover{
      background: rgba(0, 0, 0, 0.3);
    }
    @media(max-width: 820px){
      top: 0px;  
      bottom: 0px;  
      left: 0px;  
      right: 0px;  
    }
`;

const PlayButton = styled.img`
    width: 125px;
    height: 125px;
    cursor: pointer;
    opacity: 0.8;
    :hover{
      opacity: 1;
    }
    @media(max-width: 820px){
      width: 85px;
      height: 85px;  
    }
`;

import React, { useState, useCallback } from "react";
// import { Link } from "gatsby"

import Layout from "../components/layout";
// import Image from "../components/image"
import SEO from "../components/seo";
import HeaderSection from "../components/sections/HeaderSection";
import {useLanguageHook} from "../hooks/useLanguageHook";

import styled from "styled-components";
import TopSection from "../components/sections/TopSection";
import WhySection from "../components/sections/WhySection";
import StatsSection from "../components/sections/StatsSection";
import TestimonialSection from "../components/sections/TestimonialSection";
import FooterSection from "../components/sections/FooterSection";
import PhoneSection from "../components/sections/PhoneSection";
import NewPriceSection from "../components/sections/NewPriceSection";
import PricingSection from "../components/sections/PricingSection";
import PricingBlock from "../components/sections/ProcingBlock";
import MobilePricingBlock from "../components/sections/MobilePricingSection";

const IndexPage = () => {
  const [mode, setMode] = useState("month");
  const intl = useLanguageHook();
  const onSetMode = useCallback(
    (value) => {
      setMode(value);
    },
    [setMode]
  );
  return (
    <Layout>
      <SEO />

      <Wrapper>
        <HeaderSection />

        <TopSection />

        <PhoneSection />

        <WhySection />

        <StatsSection />

        <TestimonialSection />

        <MobileWrapper>
          <MobilePricingBlock mode={mode} />
        </MobileWrapper>

        <DesktopWrapper>
          <PricingBlock mode={mode} />
        </DesktopWrapper>
        <div
          style={{
            marginTop: 40,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginBottom: 30,
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            gap: 10,
          }}
        >
          <div
            style={{
              padding: 15,
              cursor: "pointer",
              backgroundColor: mode === "month" ? "#FFAC30" : "#212330",
              borderRadius: 5,
            }}
          >
            <span
              style={{
                color: mode === "month" ? "black" : "white",
                opacity: 0.5,
              }}
              onClick={() => {
                onSetMode("month");
              }}
            >
            
              {mode === "year" &&  intl.formatMessage({id: 'section.pricing.switchTo'})}{" "}
              <span style={{ fontWeight: "700", opacity: 1 }}>
              {intl.formatMessage({id: 'section.pricing.monthPlan'})}
              </span>
            </span>
          </div>
          <div
            style={{
              padding: 15,
              cursor: "pointer",
              backgroundColor: mode === "year" ? "#FFAC30" : "#212330",
              borderRadius: 5,
            }}
          >
            <span
              style={{
                color: mode === "year" ? "black" : "white",
                opacity: 0.5,
              }}
              onClick={() => {
                onSetMode("year");
              }}
            >
              {mode === "month" && intl.formatMessage({id: 'section.pricing.saveMoney'})}{" "}
              <span style={{ fontWeight: "700", opacity: 1 }}>
              {intl.formatMessage({id: 'section.pricing.yearPlan'})}
                
              </span>
            </span>
          </div>
        </div>
        {/*<NewPriceSection/>*/}
        {/*<PricingSection/>*/}

        <FooterSection />
      </Wrapper>
    </Layout>
  );
};

const DesktopWrapper = styled.div`
  @media (max-width: 720px) {
    display: none;
  }
`;

const MobileWrapper = styled.div`
  display: none;
  @media (max-width: 720px) {
    display: block;
  }
`;

const Wrapper = styled.div`
  font-family: Avenir Next Cyr;
  overflow: hidden;
`;

export default IndexPage;

import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import icon1 from './../../images/price_icon1.svg'
import icon2 from './../../images/price_icon2.svg'
import icon3 from './../../images/price_icon3.svg'

import iconIncl from './../../images/tariff_include.svg'
import hilight from './../../images/hilight.svg'
import iconExcl from './../../images/tariff_exclude.svg'
import bg from './../../images/new_tariff_bg_icon.svg'
import {Analytics} from "../../services/analytics";

const subscriptionsPrice = [{
    amount: 3,
    price: 5,
    paddle_id: '621691',
},{
    amount: 5,
    price: 7,
    paddle_id: '622128',
},{
    amount: 10,
    price: 13,
    paddle_id: '622129',
},{
    amount: 15,
    price: 18,
    paddle_id: '622130',
},{
    amount: 20,
    price: 20,
    paddle_id: '622131',
}]


const payAsGo = [{
    amount: 1,
    price: 5,
    paddle_id: '621676',
},{
    amount: 3,
    price: 12,
    paddle_id: '621688',
},{
    amount: 5,
    price: 18,
    paddle_id: '615871',
},{
    amount: 10,
    price: 28,
    paddle_id: '610181',
},{
    amount: 15,
    price: 35,
    paddle_id: '621689',
}]

export default function NewPriceSection() {
    const [tariffType, setTariffType] = useState(null);
    const [selectedSubscription, setSelectedSubscription] = useState(1);
    const [selectPayAsGo, setSelectPayAsGo] = useState(0);

    const onPurchase = (type, value) => {
        try {
            window.localStorage.setItem('auth_mode', 'signup');
            window.location.href = `https://my.subtitles.love/?signup`;
        } catch (exc) {
            console.info('Info:> exc :=', exc);
        }
    }


    return (
        <Wrapper id={'pricing'}>
            <BgIcon/>
            <PriceBlockWrapper>
                <PriceBlock>
                    <Label>Starter</Label>
                    <Title>Free 1 video</Title>
                    <FreeContainer>
                        <FreeItem icon={icon1} >
                            Max video 10 minute
                        </FreeItem>
                        <FreeItem icon={icon2}>
                            Export Quality 480p
                        </FreeItem>
                        <FreeItem icon={icon3}>
                            Watermark
                        </FreeItem>
                    </FreeContainer>
                    <SubscriptionButton onClick={() => {
                        Analytics.send('lead.tariff_click', 'lead',() => {}, {
                            event_label: 586033
                        });
                        onPurchase(null);

                    }}>Get started!</SubscriptionButton>
                </PriceBlock>
                <FeatureList>
                    <Feature exist>Automatic Captions</Feature>
                    <Feature exist>Caption Text/Timing Editor</Feature>
                    <Feature exist>20+ Supported Languages</Feature>
                    <Feature exist>Simple Style editor</Feature>
                    <Feature exist>Video Resizing</Feature>
                    <Feature>Download .SRT File</Feature>
                    <Feature>Video original size</Feature>
                    <Feature>Faster rendering</Feature>
                    <Feature>Export Quality 1080</Feature>
                    <Feature>Video Length 30min</Feature>
                    <Feature>Max size video 800mb</Feature>
                </FeatureList>
            </PriceBlockWrapper>

            <PriceBlockWrapper>

            <PriceBlock>
                <Highlight>recommended</Highlight>
                <Label>Subscription plan</Label>
                <Title>$ {Number(subscriptionsPrice[selectedSubscription].price / subscriptionsPrice[selectedSubscription].amount).toFixed(2)} <Subtitle>/video</Subtitle></Title>
                <PriceWrapper>
                    {
                        subscriptionsPrice.map(({amount, price}, i) => (
                            <PriceItem onClick={() => setSelectedSubscription(i)}>
                                <Block>
                                    <ItemPoint active={i === selectedSubscription}/>
                                    <ItemDescription>{amount} video/ month</ItemDescription>
                                </Block>
                                <ItemPrice>$ {price}</ItemPrice>
                            </PriceItem>
                        ))
                    }
                </PriceWrapper>
                <SubscriptionButton  onClick={() => {
                    Analytics.send('lead.tariff_click', 'lead',() => {}, {
                        event_label: subscriptionsPrice[selectedSubscription].paddle_id
                    });
                    onPurchase(subscriptionsPrice[selectedSubscription]);
                }}>Subscribe now</SubscriptionButton>
                <PurchaseNote>Downgrade, upgrade or cancel any time</PurchaseNote>

            </PriceBlock>
                <FeatureList>
                    <Feature exist>Automatic Captions</Feature>
                    <Feature exist>Caption Text/Timing Editor</Feature>
                    <Feature exist>20+ Supported Languages</Feature>
                    <Feature exist>Simple Style editor</Feature>
                    <Feature exist>Video Resizing</Feature>
                    <Feature exist>Download .SRT File</Feature>
                    <Feature exist>Video original size</Feature>
                    <Feature exist>Faster rendering</Feature>
                    <Feature exist>Export Quality 1080</Feature>
                    <Feature exist>Video Length 30min</Feature>
                    <Feature exist>Max size video 800mb</Feature>
                </FeatureList>

            </PriceBlockWrapper>

            <PriceBlockWrapper id="middle">
            <PriceBlock>
                <Label>Pay as you go</Label>
                <Title>$ {Number(payAsGo[selectPayAsGo].price / payAsGo[selectPayAsGo].amount).toFixed(2)} <Subtitle>/video</Subtitle></Title>
                <PriceWrapper>
                    {
                        payAsGo.map(({amount, price}, i) => (
                            <PriceItem onClick={() => setSelectPayAsGo(i)}>
                                <Block>
                                    <ItemPoint active={i === selectPayAsGo}/>
                                    <ItemDescription>{amount} video</ItemDescription>
                                </Block>
                                <ItemPrice>$ {price}</ItemPrice>
                            </PriceItem>
                        ))
                    }
                </PriceWrapper>
                <SubscriptionButton  onClick={() => {
                    Analytics.send('lead.tariff_click', 'lead',() => {}, {
                        event_label: payAsGo[selectPayAsGo].paddle_id
                    });
                    onPurchase(payAsGo[selectPayAsGo]);
                }}>Buy now</SubscriptionButton>
                <PurchaseNote>Credits available for use anytime<br/>
                    within one years of purchase.</PurchaseNote>
            </PriceBlock>
                <FeatureList>
                    <Feature exist>Automatic Captions</Feature>
                    <Feature exist>Caption Text/Timing Editor</Feature>
                    <Feature exist>20+ Supported Languages</Feature>
                    <Feature exist>Simple Style editor</Feature>
                    <Feature exist>Video Resizing</Feature>
                    <Feature exist>Download .SRT File</Feature>
                    <Feature exist>Video original size</Feature>
                    <Feature exist>Faster rendering</Feature>
                    <Feature exist>Export Quality 1080</Feature>
                    <Feature exist>Video Length 30min</Feature>
                    <Feature exist>Max size video 800mb</Feature>
                </FeatureList>
            </PriceBlockWrapper>
        </Wrapper>
    );
}

const Highlight = styled.div`
  background-image: url(${hilight});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  position: absolute;
 font-size: 11px;
 text-transform: uppercase;
 color: #FFFFFF;
 right: -10px;
    height: 47px;
    width: 126px;
    display: flex;
    justify-content: center;
    box-sizing: content-box;
    top: 12px;
    line-height: 39px;
    padding-left: 6px;
    
    @media only screen and (max-width: 1000px) {
      display: none;
    }
`;

const BgIcon = styled.div`
 width: 120px;
 height: 150px;
 position: absolute;
 z-index: 1;
 right: 0;
 top: 10px;
 background-image: url(${bg});
`;

const FeatureList = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 5px;
  margin-top: 20px;
  position: relative;
  
  @media only screen and (max-width: 900px) {
    display: none;
  }
`;

const Feature = styled.div`
 font-size: 16px;
 color: #ECECEC;
 text-align: left;
 display: flex;
 align-items: center;
 padding-left: 30px;
 margin: 10px 0;
 
 :before{
 content: '';
 position: absolute;
  background-image: url(${props => props.exist ? iconIncl : iconExcl});
  background-position: center;
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
  left: 2px;
 }
`;

const FreeContainer = styled.div`
 display: flex;
 flex-direction: column;
 box-sizing: border-box;
 margin-bottom: 40px;
 height: 230px;
`;

const FreeItem = styled.div`
font-size: 22px;
line-height: 27px;
color: #EFEFEF;
position: relative;
display: flex;
align-items: center;
padding: 40px 0 0 55px;

:before{
  background-image: url(${props => props.icon});
  width: 50px;
  height: 50px;
  background-position: left center;
  background-repeat: no-repeat;
  content: '';
  position: absolute;
  left: 0;
}
`;

const PurchaseNote = styled.div`
 color: rgba(239,239,239,0.91);
 font-size: 12px;
 text-align: center;
 padding: 5px;
 margin-top: 10px;

`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 1100px;
  padding: 50px 10px;
  box-sizing: border-box;
  margin: auto;
  @media only screen and (min-width: 680px) and (max-width: 900px) {
    flex-wrap: wrap;
    flex-direction: row;
  }
  
     @media only screen  and (max-width: 680px) {
      flex-direction: column;
  }

`;

const SubscriptionButton = styled.div`
background: #01939B;
border-radius: 5px;
color: #fff;
text-align: center;
box-sizing: border-box;
font-weight: 600;
cursor: pointer;
padding: 14px;
`;

const PriceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  height: 230px;
`;


const Block = styled.div`
  display: flex;
  align-items: center;
  color: #FFFFFF;
  font-size: 17px;

`;

const PriceItem = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
cursor: pointer;
padding: 10px 0;
`;

const ItemPoint = styled.div`
  width: 14px;
  height: 14px;
  position:relative;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  border: 1px solid #FFFFFF;
  
  :before {
    content: '';
    width: 10px;
    height: 10px;
    background: ${props => props.active ? '#FFAC30': 'none'};
    border-radius: 100px;
    position: absolute; 
    z-index: 1;
    
  }
`;

const ItemDescription = styled.div`
color: #EFEFEF;
font-size: 16px;
`;

const ItemPrice = styled.div`
color: #FFFFFF;
font-size: 16px;

`;

const Label = styled.div`
font-size: 16px;
font-weight: 500;
text-transform: uppercase;
color: #FFFFFF;
`;

const Title = styled.div`
font-weight: 500;
font-size: 32px;
color: #FFAC30;
padding: 10px 0;
`;

const Subtitle = styled.span`
 font-size: 14px;
`;

const PriceBlock = styled.div`
width: 100%;
padding: 20px;
background: #212330;
border: 2px solid rgba(255, 255, 255, 0.03);
box-sizing: border-box;
border-radius: 12px;
height: 500px;
   @media only screen and (max-width: 900px) {
    flex: 1 1 40%;
    max-width: 100%;
    min-height: 100%;
  }
`;

const PriceBlockWrapper = styled.div`
max-width: 350px;
width: 100%;
position: relative;
z-index: 2;
  margin: 10px;
  
   @media only screen and (min-width: 680px) and (max-width: 900px) {
    flex: 1 1 40%;
    max-width: 100%;
  }
  
     @media only screen and (max-width: 680px) {
      margin: 0 0 20px;
    max-width: 100%;
  }
`;

import React from 'react';
import styled from 'styled-components';
import MyContainer from "../containers/MyContainer";
import {useLanguageHook} from "../../hooks/useLanguageHook";

const shiImg = require('../../images/shi.svg');
const shiImgHov = require('../../images/shi_h.svg');

export default function WhySection(props) {
    const intl = useLanguageHook();

    const ITEMS = [
        {
            width: 24,
            height: 24,
            image: require('../../images/im_1.svg'),
            // title: 'Автоматические субтитры',
            title: intl.formatMessage({id: 'section.why.item1.title'}),
            text: intl.formatMessage({id: 'section.why.item1.text'}),
        },
        {
            width: 20,
            height: 31,
            image: require('../../images/shPhone.svg'),
            // title: 'Ресайз видео для соцсетей',
            title: intl.formatMessage({id: 'section.why.item2.title'}),
            text: intl.formatMessage({id: 'section.why.item2.text'}),
        },
        {
            width: 32,
            height: 32,
            image: require('../../images/im_3.svg'),
            // title: 'Конвертатор',
            title: intl.formatMessage({id: 'section.why.item3.title'}),
            text: intl.formatMessage({id: 'section.why.item3.text'}),
        },
        {
            width: 29,
            height: 29,
            image: require('../../images/im_4.svg'),
            // title: 'Экономь время',
            title: intl.formatMessage({id: 'section.why.item4.title'}),
            text: intl.formatMessage({id: 'section.why.item4.text'}),
        },
        {
            width: 29,
            height: 23,
            image: require('../../images/im_5.png'),
            // title: 'Сохраняй аудиодорожку',
            title: intl.formatMessage({id: 'section.why.item5.title'}),
            text: intl.formatMessage({id: 'section.why.item5.text'}),
        },
        {
            width: 36,
            height: 36,
            image: require('../../images/im_6.svg'),
            // title: 'Низкая стоимость',
            title: intl.formatMessage({id: 'section.why.item6.title'}),
            text: intl.formatMessage({id: 'section.why.item6.text'}),
        }
    ]

    return (
        <Wrapper id={'features'}>

            <MyContainer>

                <Cont>

                    <Heading>{intl.formatMessage({id: 'section.why.head'})}</Heading>

                    <BoxesPlaceholder>
                        {ITEMS.map((it, j) => {
                            return (
                                <Box key={j}>
                                    <ImgSection>
                                        <ShiDiv>
                                            <PhoneImg src={it.image} {...it} />
                                        </ShiDiv>
                                    </ImgSection>
                                    <Name>
                                        {it.title}
                                    </Name>
                                    <Description>
                                        {it.text}
                                    </Description>
                                </Box>
                            )
                        })}
                    </BoxesPlaceholder>

                </Cont>

            </MyContainer>

            <TStrIm src={require('../../images/ma_str.svg')}/>

        </Wrapper>
    );
}

const Wrapper = styled.div`
      color: white;
      margin-top: 70px;
      position: relative;
`;

const Cont = styled.div`
    border-top: 1px solid #212330;
    padding-top: 48px;
`;

const TStrIm = styled.img`
    position: absolute;
    right: 60px;
    width: 120px;
    height: 220px;
    top: -290px;
    @media(max-width: 1400px){
      display: none;
    }
`;


const Heading = styled.div`
    text-align: center;
    color: white;
    margin-bottom: 75px;
    font-style: normal;
    font-weight: bold;
    font-size: 34px;
    line-height: 46px;
`;

const BoxesPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
`;

const Box = styled.div`
    padding: 25px;
    box-sizing: border-box;
    margin: 10px;
    width: calc(33.33% - 20px);
    :hover{
      background: #212330;
      box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.1);
      border-radius: 12px;
    }
    @media(max-width: 820px){
      width: calc(50% - 20px);
    }
    @media(max-width: 520px){
      width: calc(100% - 20px);
    }
`;

const ImgSection = styled.div`
    margin-bottom: 13px;
`;

const Name = styled.div`
    font-style: normal;
    font-weight: bold;
    font-size: 21px;
    line-height: 26px;
    margin-bottom: 12px;
`;

const Description = styled.div`
    font-weight: normal;
    font-size: 16px;
    line-height: 28px;
    color: #EFEFEF;
`;

const ShiDiv = styled.div`
    width: 58.08px;
    height: 56.69px;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url(${shiImg});
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    ${Box}:hover & {
      background-image: url(${shiImgHov});
    }
`;

const PhoneImg = styled.img`
    width: ${props => props.width}px;
    height: ${props => props.height}px;
`;


import React, {useMemo} from 'react';

import styled from 'styled-components';
import {Analytics} from "../../services/analytics";
import {useLanguageHook} from "../../hooks/useLanguageHook";
const crossImg = require('../../images/red_red_close.svg');
const checkImg = require('../../images/white_check.svg');

export default function PricingBlock(props) {
    const { mode = 'month'} = props;
    const intl = useLanguageHook();

    const ITEMS = [
        {
            id: '8oCk3S8ZJZ',
            paddle_id: '586033',
            isFree: true,
            video_num: '1 video',
            translator: false,
            no_watermark: false,
            automatic_captions: true,
            timing_editor: true,
            languages_20: true,
            style_editor: true,
            video_resizing: false,
            srt: false,
            original_size: false,
            faster_rendering: false,
            export_1080p: false,
            video_length: '20 min',
            max_file_size: '500mb',
            // background: '#212330',
            background: 'transparent',
            price: 0,
            name: 'Free',
            nameColor: '#FFFFFF',
            motto: 'Free',
            mottoColor: '#5C5C5C',
            subMotto: 'FOREVER',
            subMottoColor: '#5C5C5C',
            centerText: '1 video / 1 render',
            centerColor: '#EB5E4F',
            text: `
                <div class="feature_item" >
                    Automatic Captions
                </div>
                <div class="feature_item" >
                    Caption Text/Timing Editor
                </div>
                <div class="feature_item" >
                    12+ Supported Languages
                </div>
                <div class="feature_item" >
                    Simple Style editor
                </div>
                <div class="feature_item" >
                    Video Resizing
                </div>
                <div class="feature_item" >
                    Watermark
                </div>
            `,
            textColor: '#FFFCFC',
            buttonColor: '#01939B',
            buttonTextColor: '#FFFFFF',
            buttonText: 'SUBSCRIBE'
        },
        {
            id: 'zdCnvJmPIo',
            paddle_id: '633614',
            price: 5,
            video_num: '1 video',
            translator: false,
            no_watermark: true,
            automatic_captions: true,
            timing_editor: true,
            languages_20: true,
            style_editor: true,
            video_resizing: true,
            srt: false,
            original_size: true,
            faster_rendering: true,
            export_1080p: true,
            video_length: '30 min',
            max_file_size: '800mb',
            // background: '#212330',
            background: 'transparent',
            name: 'Light',
            nameColor: '#FFFFFF',
            motto: '$5',
            mottoColor: '#5C5C5C',
            subMotto: 'PER MONTH',
            subMottoColor: '#5C5C5C',
            centerText: '1 video / 5 renders',
            centerColor: '#EB5E4F',
            text: `
                <div class="feature_item" >
                    Automatic Captions
                </div>
                <div class="feature_item" >
                    Caption Text/Timing Editor
                </div>
                <div class="feature_item" >
                    12+ Supported Languages
                </div>
                <div class="feature_item" >
                    Advanced Style editor
                </div>
                <div class="feature_item" >
                    Video Resizing
                </div>
                <div class="feature_item" >
                    Download .SRT File
                </div>
                <div class="feature_item" >
                    No watermark
                </div>
            `,
            textColor: '#FFFCFC',
    
            buttonColor: '#01939B',
            buttonTextColor: '#FFFFFF',
            buttonText: 'SUBSCRIBE'
        },
        {
            id: 'EEySyPbAeu',
            paddle_id: '586001',
            price: 13,
            video_num: '15 videos',
            translator: true,
            no_watermark: true,
            automatic_captions: true,
            timing_editor: true,
            languages_20: true,
            style_editor: true,
            video_resizing: true,
            srt: true,
            original_size: true,
            faster_rendering: true,
            export_1080p: true,
            video_length: '40 min',
            max_file_size: '1.5GB',
            // background: '#212330',
            // background: 'transparent',
            background: '#FFAC30',
            priceColor: '#212330',
            nameColor: '#212330',
            name: 'Medium',
            // nameColor: '#FFFFFF',
            motto: '$13',
            mottoColor: '#5C5C5C',
            subMotto: 'PER MONTH',
            subMottoColor: '#5C5C5C',
            centerText: '15 videos / 50 renders',
            centerColor: '#EB5E4F',
            text: `
                <div class="feature_item" >
                    Automatic Captions
                </div>
                <div class="feature_item" >
                    Caption Text/Timing Editor
                </div>
                <div class="feature_item" >
                    12+ Supported Languages
                </div>
                <div class="feature_item" >
                    Advanced Style editor
                </div>
                <div class="feature_item" >
                    Video Resizing
                </div>
                <div class="feature_item" >
                    Download .SRT File
                </div>
                <div class="feature_item" >
                    No watermark
                </div>
            `,
            textColor: '#FFFCFC',
            buttonColor: '#FFFFFF',
            buttonTextColor: '#212330',
            buttonText: 'SUBSCRIBE'
        },
        {
            id: '2v7DU1gu8l',
            paddle_id: '586002',
            background: 'transparent',
            // background: '#FFAC30',
            video_num: '75 videos',
            translator: true,
            no_watermark: true,
            automatic_captions: true,
            timing_editor: true,
            languages_20: true,
            style_editor: true,
            video_resizing: true,
            srt: true,
            original_size: true,
            faster_rendering: true,
            export_1080p: true,
            video_length: '60 min',
            max_file_size: '2GB',
            price: 49,
            name: 'Premium',
            // priceColor: '#212330',
            nameColor: '#212330',
            motto: '$49',
            mottoColor: '#FFFFFF',
            subMotto: 'PER MONTH',
            subMottoColor: '#FFFFFF',
    
            centerText: '75 videos / 300 renders',
            centerColor: '#212330',
    
            text: `
                <div class="feature_item" >
                    Automatic Captions
                </div>
                <div class="feature_item" >
                    Caption Text/Timing Editor
                </div>
                <div class="feature_item" >
                    12+ Supported Languages
                </div>
                <div class="feature_item" >
                    Advanced Style editor
                </div>
                <div class="feature_item" >
                    Video Resizing
                </div>
                <div class="feature_item" >
                    Faster rendering
                </div>
                <div class="feature_item" >
                    Download .SRT File
                </div>
                <div class="feature_item" >
                    No watermark
                </div>
            `,
            textColor: '#FFFFFF',
    
            buttonColor: '#01939B',
            buttonTextColor: '#FFFFFF',
            buttonText: 'SUBSCRIBE'
    
        },
        {
            isYear: true,
            id: 'opdSjVp2xQ',
            paddle_id: '646321',
            price: 110,
            video_num: '180 videos',
            translator: true,
            no_watermark: true,
            automatic_captions: true,
            timing_editor: true,
            languages_20: true,
            style_editor: true,
            video_resizing: true,
            srt: true,
            original_size: true,
            faster_rendering: true,
            export_1080p: true,
            video_length: '40 min',
            max_file_size: '2GB',
            // background: '#212330',
            // background: 'transparent',
            background: '#FFAC30',
    
            priceColor: '#212330',
            nameColor: '#212330',
    
            name: 'Medium',
            // nameColor: '#FFFFFF',
            motto: '$110',
            mottoColor: '#5C5C5C',
            subMotto: 'PER YEAR',
            subMottoColor: '#5C5C5C',
            centerText: '180 videos / 1800 renders',
            centerColor: '#EB5E4F',
            text: `
                <div class="feature_item" >
                    Automatic Captions
                </div>
                <div class="feature_item" >
                    Caption Text/Timing Editor
                </div>
                <div class="feature_item" >
                    12+ Supported Languages
                </div>
                <div class="feature_item" >
                    Advanced Style editor
                </div>
                <div class="feature_item" >
                    Video Resizing
                </div>
                <div class="feature_item" >
                    Download .SRT File
                </div>
                <div class="feature_item" >
                    No watermark
                </div>
            `,
            textColor: '#FFFCFC',
    
            buttonColor: '#FFFFFF',
            buttonTextColor: '#212330',
            buttonText: 'SUBSCRIBE'
        },
    
        {
    
            isYear: true,
    
            id: '27MZgMccfj',
            paddle_id: '646322',
    
            background: 'transparent',
    
            // background: '#FFAC30',
    
            video_num: '900 videos',
    
            translator: true,
            no_watermark: true,
            automatic_captions: true,
            timing_editor: true,
            languages_20: true,
            style_editor: true,
            video_resizing: true,
            srt: true,
            original_size: true,
            faster_rendering: true,
            export_1080p: true,
            video_length: '60 min',
            max_file_size: '2.5GB',
    
            price: 410,
    
            name: 'Premium',
            // priceColor: '#212330',
            nameColor: '#212330',
            motto: '$410',
            mottoColor: '#FFFFFF',
            subMotto: 'PER YEAR',
            subMottoColor: '#FFFFFF',
    
            centerText: '900 videos / 9000 renders',
            centerColor: '#212330',
    
            text: `
                <div class="feature_item" >
                    Automatic Captions
                </div>
                <div class="feature_item" >
                    Caption Text/Timing Editor
                </div>
                <div class="feature_item" >
                    20+ Supported Languages
                </div>
                <div class="feature_item" >
                    Advanced Style editor
                </div>
                <div class="feature_item" >
                    Video Resizing
                </div>
                <div class="feature_item" >
                    Faster rendering
                </div>
                <div class="feature_item" >
                    Download .SRT File
                </div>
                <div class="feature_item" >
                    No watermark
                </div>
            `,
            textColor: '#FFFFFF',
    
            buttonColor: '#01939B',
            buttonTextColor: '#FFFFFF',
            buttonText: 'SUBSCRIBE'
    
        },
    
    ]

    const FEATURES = [
        {
            name: 'video_num',
            label: intl.formatMessage({id: 'section.pricing.feature1'}),
            isText: true
        },
        {
            name: 'automatic_captions',
            label: intl.formatMessage({id: 'section.pricing.feature2'}),
        },
        {
            name: 'timing_editor',
            label: intl.formatMessage({id: 'section.pricing.feature3'}),
        },
        {
            name: 'languages_20',
            label: intl.formatMessage({id: 'section.pricing.feature4'}),
        },
        {
            name: 'style_editor',
            label: intl.formatMessage({id: 'section.pricing.feature5'}),
        },
        {
            name: 'video_resizing',
            label: intl.formatMessage({id: 'section.pricing.feature6'}),
        },
        {
            name: 'translator',
            label: intl.formatMessage({id: 'section.pricing.feature7'}),
        },
        {
            name: 'no_watermark',
            label: intl.formatMessage({id: 'section.pricing.feature8'}),
        },
        {
            name: 'srt',
            label: intl.formatMessage({id: 'section.pricing.feature9'}),
        },
        {
            name: 'original_size',
            label: intl.formatMessage({id: 'section.pricing.feature10'}),
        },
        {
            name: 'faster_rendering',
            label: intl.formatMessage({id: 'section.pricing.feature11'}),
        },
        {
            name: 'export_1080p',
            label: intl.formatMessage({id: 'section.pricing.feature12'}),
        },
        {
            name: 'max_duration',
            label: intl.formatMessage({id: 'section.pricing.feature13'}),
            isText: true
        },
        {
            name: 'max_file',
            label: intl.formatMessage({id: 'section.pricing.feature14'}),
            isText: true
        }
    ];
    const items = useMemo(() => (mode == 'month') ? ITEMS.filter(x => (x.isYear != true)) : ITEMS.filter(x => (x.isFree == true || x.isYear == true)), [mode,ITEMS ])
    console.log("items", items.length)
    return (
        <Wrapper>


            <DesktopPlaceholder>

                <TopRow>
                    <RowLeftColumn>

                    </RowLeftColumn>
                    {items.map((it, i) => {
                        let isFree = it.isFree;
                        return (
                            <RowColumn key={i} background={it.background}
                                       style={{paddingTop: 30, borderTopLeftRadius: 12, borderTopRightRadius: 12}}>
                                <RowLabel>
                                    {it.name}
                                </RowLabel>
                                <RowPrice>
                                    <RowPriceSpan color={it.priceColor}>
                                        {`$${it.price}`}
                                    </RowPriceSpan>
                                    <RowPriceMonthSpan>
                                    {`/${intl.formatMessage({id: `section.pricing.${mode}`})}`}
                                    </RowPriceMonthSpan>
                                </RowPrice>
                                <RowActionPlaceholder>
                                    <Button {...it}
                                            onClick={async () => {
                                                Analytics.send('lead.account.start', 'lead');
                                                // window.ga('set', 'dimension1', it.name.toLowerCase());
                                                try {
                                                    window.localStorage.setItem('auth_mode', 'signup');
                                                    window.location.href = `https://my.subtitles.love/?signup`;
                                                } catch (exc) {

                                                }
                                            }}>
                                        {it.buttonText}
                                    </Button>
                                </RowActionPlaceholder>
                            </RowColumn>
                        )
                    })}
                </TopRow>

                <div>
                    {FEATURES.map((it, i) => {
                        return (
                            <Row key={i}>
                                <RowLeftColumn style={{
                                    color: '#ECECEC',
                                    paddingTop: (i == 0 ? 30 : (i == FEATURES.length - 1) ? 10 : 0)
                                }}>
                                    {it.label}
                                </RowLeftColumn>
                                {items.map((a, j) => {
                                    let isCheck = (it.isText != true && a[it.name]);
                                    let imgSrc = isCheck ? checkImg : crossImg;
                                    let txt = (it.isText == true) ? a[it.name] : '';
                                    return (
                                        <RowColumn2 key={j} background={a.background} style={{
                                            paddingTop: (i == 0 ? 30 : (i == FEATURES.length - 1) ? 10 : 5),
                                            paddingBottom: (i == FEATURES.length - 1) ? 20 : 5,
                                            borderBottomLeftRadius: (i == FEATURES.length - 1) ? 12 : 0,
                                            borderBottomRightRadius: (i == FEATURES.length - 1) ? 12 : 0,
                                            marginTop: (i == FEATURES.length - 1) ? -1 : 0,
                                            fontWeight: 'bold',
                                            color: (a.priceColor == undefined) ? 'white' : '#171822'
                                        }}>
                                            {it.isText ? txt : <CheckImg src={imgSrc}/>}
                                        </RowColumn2>
                                    )
                                })}

                            </Row>
                        )
                    })}
                </div>

            </DesktopPlaceholder>

        </Wrapper>
    );
}


const Wrapper = styled.div`
  color: white;
  max-width: 1100px;
  box-sizing: border-box;
  padding: 10px;
  margin: 50px auto auto;
`;

const TopRow = styled.div`
    display: flex;
    flex-direction: row;
`;

const CheckImg = styled.img`
    width: 18px;
`;

const RowColumn = styled.div`
    flex: 1;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
    background: ${props => props.background};
`;

const RowColumn2 = styled(RowColumn)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 45px;
    background: ${props => props.background};
    padding-bottom: 10px;
`;

const RowLabel = styled.div`
    color: white;
    font-weight: bold;
    font-size: 28px;
    text-align: center;
    margin-bottom: 20px;
`;

const RowActionPlaceholder = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
`;

const RowPrice = styled.div`
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
`;

const RowPriceSpan = styled.div`
    color: ${props => (props.color == undefined ? '#FFAC30' : props.color)};
    font-size: 32px;
    font-weight: bold;
`;

const RowPriceMonthSpan = styled.div`
    color: #848199;
    font-size: 17px;
    margin-left: 3px;
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const RowLeftColumn = styled.div`
    width: 200px;
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const DesktopPlaceholder = styled.div`
    @media(max-width: 800px){
      display: none;
    }
`;

const MobilePlaceholder = styled.div`
    display: none;
    @media(max-width: 800px){
      display: block;
    }
`;

const Cont = styled.div`
    padding-top: 48px;
    width: 920px;
    margin: 0 auto;
    box-sizing: border-box;
    @media(max-width: 920px){
      width: 100%;
    }
`;

const BoxesPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
`;

const Box = styled.div`
    position: relative;
    padding: 25px;
    box-sizing: border-box;
    margin: 15px;
    width: calc(33.33% - 30px);
    border: 2px solid rgba(255, 255, 255, 0.03);
    border-radius: 16px;
    background: ${props => props.background};
    @media(max-width: 820px){
      width: calc(50% - 30px);
    }
    @media(max-width: 520px){
      width: calc(100% - 30px);
    }
`;

const Name = styled.div`
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 48px;
    text-align: center;
    letter-spacing: -1.25px;
    color: ${props => props.color};
`;

const Motto = styled.div`
    font-size: 32px;
    line-height: 48px;
    text-align: center;
    letter-spacing: -1.25px;
    color: ${props => props.color};
    margin-top: 10px;
    font-style: italic;
    font-weight: bold;
`;

const SubMotto = styled.div`
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    letter-spacing: 2.5px;
    color: ${props => props.color};
    margin-top: 6px;
`;

const CenterText = styled.div`
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 32px;
    text-align: center;
    letter-spacing: -0.25px;
    color: ${props => props.color};
    margin-top: 40px;
`;

const BottomText = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.25px;
    color: ${props => props.color};
    margin-top: 40px;
`;

const ButtonPlaceholder = styled.div`
    margin-top: 24px;
`;

const Button = styled.div`
    background: ${props => props.buttonColor};
    color: ${props => props.buttonTextColor};
    
    border-radius: 5px;
    padding-left: 16px;
    padding-right: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 17px;
    height: 44px;
    font-weight: bold;
    cursor: pointer;
    :hover{
      opacity: 0.8;
    }
`;

const MyBox = styled.div`
    background: transparent;
    color: ${props => props.buttonTextColor};
    border: 2px solid white;
    border-radius: 5px;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 17px;
    height: 44px;
    font-weight: bold;
    box-sizing: border-box;
`;



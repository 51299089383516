import React from 'react';
import styled from 'styled-components';
import MyContainer from "../containers/MyContainer";
import SocialGalleryTool from "../tools/SocialGalleryTool";
import {useLanguageHook} from "../../hooks/useLanguageHook";

export default function PhoneSection(props) {
    const intl = useLanguageHook();


    const ITEMS = [
        {
            image: require('../../images/ph_1.svg'),
            title: intl.formatMessage({id: 'section.phone.item1.title'}),
            text: intl.formatMessage({id: 'section.phone.item1.text'}),
            hasArrow: true,

        },
        {
            image: require('../../images/ph_2.svg'),
            title: intl.formatMessage({id: 'section.phone.item2.title'}),
            text: intl.formatMessage({id: 'section.phone.item2.text'}),
            hasArrow: true
        },
        {
            image: require('../../images/ph_3.svg'),
            title: intl.formatMessage({id: 'section.phone.item3.title'}),
            text: intl.formatMessage({id: 'section.phone.item3.text'}),
            hasArrow: false
        },

    ]

    return (
        <Wrapper>

            <MyContainer>

                <Row>

                    <Left>
                        <PhonePlaceholder className={''} >
                            <PhoneImg src={require('../../images/iphone_back.png')} />
                            <GalleryPlaceholder>
                                <SocialGalleryTool/>
                            </GalleryPlaceholder>
                        </PhonePlaceholder>
                    </Left>

                    <Right>
                        {ITEMS.map((it, i) => {
                            return (
                                <React.Fragment key={i}>
                                    <Item>
                                        <ItImage src={it.image}/>
                                        <ItContent>
                                            <ItName>
                                                {it.title}
                                            </ItName>
                                            <ItDescr>
                                                {it.text}
                                            </ItDescr>
                                        </ItContent>
                                    </Item>
                                    {it.hasArrow == false ? null :
                                        <BArrowPlaceholder>
                                            <BarrowImg src={require('../../images/b_arrow.svg')} />
                                        </BArrowPlaceholder>
                                    }
                                </React.Fragment>
                            )
                        })}
                    </Right>



                </Row>

            </MyContainer>

        </Wrapper>
    );
}

const GalleryPlaceholder = styled.div`
    position: absolute;
    top: 0px;
    right: 0px;
    left: 0px;
    z-index: 2;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
`;

const Wrapper = styled.div`
    min-height: 800px;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: stretch;
    margin-top: 50px;
`;

const ItContent = styled.div`
    
`;

const ItName = styled.div`
    font-style: normal;
    font-weight: bold;
    font-size: 21px;
    line-height: 26px;
    color: #FFFFFF;
    margin-bottom: 10px;
    @media(max-width: 620px){
      margin-bottom: 5px;
    }
`;

const ItDescr = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 28px;
    color: #EFEFEF;
    @media(max-width: 620px){
      font-size: 14px;
      line-height: 20px;
    }
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    width: 1020px;
    margin: 0 auto;
    box-sizing: border-box;
    @media(max-width: 1020px){
      width: 100%;
    }
    @media(max-width: 820px){
      display: block;
    }
`;

const Left = styled.div`
    flex: 1;
    padding-right: 40px;
    box-sizing: border-box;
    @media(max-width: 820px){
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      margin-top: 20px;
      padding-right: 0px;
    }
`;

const BArrowPlaceholder = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 30px;
`;

const BarrowImg = styled.img`
    height: 30px;
    width: 92px;
    @media(max-width: 620px){
      height: 20px;
    }
`;

const Right = styled.div`
    flex: 1;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    @media(max-width: 720px){
      margin-top: 30px;
    }
`;

const Item = styled.div`
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
`;

const ItImage = styled.img`
    width: 132px;
    height: 132px;
    margin-right: 65px;
    @media(max-width: 720px){
      margin-right: 35px;  
      width: 92px;
      height: 92px;
    }
    @media(max-width: 620px){
      margin-right: 15px;  
      width: 92px;
      height: 92px;
    }
`;

const PhonePlaceholder = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 408px;
    margin: 0 auto;
    @media(max-width: 620px){
      width: calc(90vw - 20px);
    }
`;

const PhoneImg = styled.img`
    width: 100%;
    margin: 0 auto;
`;


import React from 'react';
import styled from 'styled-components';
import MyContainer from "../containers/MyContainer";
import {useLanguageHook} from "../../hooks/useLanguageHook";

export default function TestimonialSection(props) {
    const intl = useLanguageHook();

    return (
        <Wrapper>

            <MyContainer>

                <Row>

                    <Left>
                        <Motto>{intl.formatMessage({id: 'section.testimonials.motto'})}</Motto>
                        <SubMotto>
                            {intl.formatMessage({id: 'section.testimonials.subMotto'})}
                        </SubMotto>
                        <ButtonPlaceholder>
                            <StartButton onClick={() => {
                                try {
                                    window.localStorage.setItem('auth_mode', 'signup');
                                    window.location.href = `https://my.subtitles.love/?signup`;
                                } catch (exc) {

                                }
                            }}>
                                {intl.formatMessage({id: 'section.top.start'})}
                            </StartButton>
                        </ButtonPlaceholder>

                    </Left>

                    <Right>
                        <TeRow>
                            {ITEMS.map((it, j) => {
                                return (
                                    <Box key={j}>
                                        <BTop onClick={() => {
                                            let win = window.open(it.socialLink, '_blank');
                                            win.focus();
                                        }} >
                                            <BTopLeft>
                                                <Ava src={it.avatar}/>
                                                <TopName>
                                                    {it.name}
                                                </TopName>
                                            </BTopLeft>
                                            <TwSocImg src={require('../../images/f_.svg')}/>
                                        </BTop>
                                        <BCont>
                                            {it.text}
                                        </BCont>
                                        <DatePlaceholder>
                                            {it.dateText}
                                        </DatePlaceholder>
                                    </Box>
                                )
                            })}
                        </TeRow>
                    </Right>

                </Row>

            </MyContainer>

            <TStrIm src={require('../../images/testi_str.svg')}/>

        </Wrapper>
    );
}

const Box = styled.div`
    width: calc(50% - 20px);
    margin: 10px;
    background: #333541;
    box-sizing: border-box;
    padding: 20px;
    border-radius: 16px;
    @media(max-width: 920px){
      width: calc(100% - 20px);  
    }
`;

const BTop = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

const BCont = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 20px;
    color: #FFFFFF;
    mix-blend-mode: normal;
    opacity: 0.8;
    margin-top: 15px;
`;

const DatePlaceholder = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    color: #FFFFFF;
    mix-blend-mode: normal;
    opacity: 0.5;
    margin-top: 20px;
`;

const BTopLeft = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
`;

const Ava = styled.div`
    width: 36px;
    height: 36px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 1000px;
    margin-right: 8px;
    background-image: url(${props => props.src});
`;

const TopName = styled.div`
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    color: #FFFFFF;
`;

const TeRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
`;

const TwSocImg = styled.img`
    height: 16px;
    cursor: pointer;
`;

const Wrapper = styled.div`
    min-height: 800px;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: stretch;
    background: #212330;
    position: relative;
    width: 100%;
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    @media(max-width: 820px){
      display: block;
    }
`;

const Left = styled.div`
    flex: 1;
    padding-right: 40px;
    box-sizing: border-box;
    @media(max-width: 820px){
      padding: 10px;
    }
`;

const Right = styled.div`
    flex: 1;
    //background: lightgrey;
    align-self: stretch;
    display: flex;
    align-items: center;
    justify-content: center;
`;


const Motto = styled.div`
    font-style: normal;
    font-weight: bold;
    font-size: 48px;
    line-height: 50px;
    color: white;
    margin-bottom: 42px;
`;

const SubMotto = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 28px;
    color: #FFFFFF;
`;

const StartButton = styled.div`
    background: #EB5E4F;
    border-radius: 5px;
    padding-left: 20px;
    padding-right: 20px;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 17px;
    color: white;
    height: 46px;
    font-weight: bold;
    cursor: pointer;
    :hover{
      opacity: 0.8;
    }
`;

const ButtonPlaceholder = styled.div`
    margin-top: 42px;
`;

const TStrIm = styled.img`
    position: absolute;
    right: 180px;
    width: 119px;
    height: 150px;
    bottom: -150px;
    @media(max-width: 1400px){
      display: none;
    }
`;

const ITEMS = [
    {
        name: 'David Richardson',
        dateText: 'Jan 20, 2020',
        socialLink: 'https://www.facebook.com/dratster',
        avatar: require('../../images/dave_mini.jpg'),
        text: `I use Subtitles.Love for every social media content I create. With a click of a button you get videos ready for any social network. Saves a lot of time!`
    },
    {
        name: 'Irina Lyan',
        dateText: 'Jan 18, 2020',
        socialLink: 'https://www.facebook.com/irina.lyan.8',
        avatar: require('../../images/ira_mini.jpg'),
        text: 'Subtitles.Love is a real gem! I started using it a couple months ago and it completely changed the way how I create subtitled videos. I can only recommend it!'
    },
    {
        name: 'Sabir Shaykhlislamov',
        dateText: 'Oct 6, 2019',
        socialLink: 'https://www.facebook.com/sabir.shaykhlislamov',
        avatar: require('../../images/sabir_opt.jpg'),
        text: `Every content producer should definitely subtitle their videos! It really increases viewers' engagement.`
    },
    {
        name: 'Yury Lirmak',
        dateText: 'Nov 23, 2019',
        socialLink: 'https://www.facebook.com/yury.lirmak',
        avatar: require('../../images/lirmak_mini.jpg'),
        // text: 'Subtitles.Love is a real gem! I started using it a couple months ago and it completely changed the way how I create subtitled videos. I can only recommend it!'
        text: `I'm fed up with my outdated video editor but hate the idea to install the new one. All I need is videos with the subs. I used to make them myself. I love subtitles.love that also saves a lot of my time.`
    },
    {
        name: 'Stan Black',
        dateText: 'Jan 27, 2020',
        socialLink: 'https://www.facebook.com/stanislav.chernomorchenko',
        avatar: require('../../images/stan_mini.jpg'),
        text: 'I love subtitles.love! Upload your video, subtitles will be generated automatically! Worth trying!'
    },
    {
        name: 'Anton Danshin',
        dateText: 'Sept 2, 2019',
        socialLink: 'https://www.facebook.com/anton.danshin',
        avatar: require('../../images/danon_mini.jpg'),
        text: `It took on average 2 hours to make subtitles for one video. With subtitles.love it takes just 10 minutes. Saved a lot of my time!`
    }
]

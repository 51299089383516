import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import useComponentSize from '@rehooks/component-size'

//proportion: w / h
const getContainerDimensions = (containerSize = {}, proportion = 1.0) => {
    let {width, height} = containerSize;
    let containerRatio = (width == undefined) ? 1 : (1.0 * width / height);
    let videoWidth = width, videoHeight = height;
    if (proportion > containerRatio) { // wider
        videoWidth = width;
        videoHeight = 1.0 * width / proportion
    } else { // narrower
        videoHeight = height;
        videoWidth = 1.0 * height * proportion;
    }
    return {
        width: videoWidth,
        height: videoHeight
    }
}

const squareVideo = require('../../videos/square_not_muted.mp4');
const fbVideo = require('../../videos/fbVideo.mp4');
const youtubeVideo = require('../../videos/youtubeVideo.mp4');

export default function SocialGalleryTool(props) {
    const [n, setN] = useState(0);
    const it = ITEMS[n];
    let {subImage, topImage, bg, icon, w, h} = it;
    let proportion = 1.0 * w / h;
    const contRef = useRef();
    let playerPlaceholderSize = useComponentSize(contRef);
    let playerSize = getContainerDimensions(playerPlaceholderSize, proportion);

    return (
        <Wrapper>

            <TopButtonsPlaceholder>
                {ITEMS.map((it, k) => {
                    return (
                        <TopItem selected={(k == n)} src={it.icon} onClick={() => setN(k)}/>
                    )
                })}
            </TopButtonsPlaceholder>

            <SlidesPlaceholder>
                <SlideItem>
                    {topImage == undefined ? null :
                        <TopItemPlaceholder>
                            <TopOverImage src={topImage}/>
                        </TopItemPlaceholder>
                    }

                    <ItemCenterImagePlaceholder>
                        <FittedContainer ref={contRef}>
                            <Video key={`EE_${n}`} {...playerSize} controls={false} muted={true} autoPlay={true} loop={true} >
                                <source src={ITEMS[n].video}/>
                            </Video>
                        </FittedContainer>
                    </ItemCenterImagePlaceholder>

                    {subImage == undefined ? null :
                        <BottomItemPlaceholder>
                            <BottomImage src={subImage}/>
                        </BottomItemPlaceholder>
                    }
                </SlideItem>
            </SlidesPlaceholder>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    position: relative;
    width: 100%;    
    height: 100%;
    box-sizing: border-box;    
`;

const SlidesPlaceholder = styled.div`
    z-index: 4;
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
`;

const SlideItem = styled.div`
    width: calc(100% - 40px);
    height: calc(100% - 80px);
    margin: 0 auto;
    box-sizing: border-box;
    margin-top: 138px;
    padding-top: 20px;
    padding-bottom: 100px;
    
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
`;

const ItemCenterImagePlaceholder = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const FittedContainer = styled.div`
    height: 100%;
    width: 124%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const PlayerPlaceholder = styled.div`
    width: ${props => props.width}px;
    height: ${props => props.height}px;
    background: lightgrey;
    background-image: url(${props => props.src});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border: 6px solid white;
    border-radius: 4px;
`;

const Video = styled.video`
    width: ${props => props.width}px;
    height: ${props => props.height}px;
    background: white;
    border-radius: 4px;
    border: 6px solid white;
    box-sizing: border-box;
`;


const TopButtonsPlaceholder = styled.div`
    z-index: 5;
    top: 50px;
    left: 20px;
    right: 20px;
    position: absolute;
    
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    
    //background: rgba(255, 255, 255, 0.9);
    
`;

const TopItem = styled.img`
    margin: 15px;
    width: 60px;
    cursor: pointer;
    opacity: ${props => (props.selected == true ? 1 : 0.3)};
    :hover{
      opacity: 0.8;
    }
`;

const BgImage = styled.div`
    width: 100%;
    height: 100%;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(${props => props.src});
    
`;


const BottomItemPlaceholder = styled.div`
    margin-top: 20px;
`;

const BottomImage = styled.img`
    width: 100%;
`;

const TopItemPlaceholder = styled.div`
    margin-bottom: 10px;
`;

const TopOverImage = styled.img`
    height: 24px;
`;

const ITEMS = [
    {
        icon: require('../../images/inst_a.png'),
        bg: require('../../images/inst_df.jpg'),
        subImage: require('../../images/sub_insta.png'),
        topImage: require('../../images/subtitleslove_over.png'),
        w: 1.0,
        h: 1.0,
        video: squareVideo
    },
    {
        icon: require('../../images/fb_a.png'),
        bg: require('../../images/fb_sa.jpg'),
        topImage: require('../../images/subtitleslove_over.png'),
        w: 4.0,
        h: 5.0,
        video: fbVideo
    },
    {
        icon: require('../../images/you_a.png'),
        bg: require('../../images/you_st.png'),
        subImage: require('../../images/sub_youtube.png'),
        w: 16.0,
        h: 9.0,
        video: youtubeVideo
    },

]

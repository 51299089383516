import React from 'react';
import styled from 'styled-components';
import MyContainer from "../containers/MyContainer";

import './style.css'
import {Analytics} from "../../services/analytics";

export default function PricingSection(props) {

    return (
        <Wrapper id={'pricing'}>
            <MyContainer>
                <Cont>
                    <BoxesPlaceholder>
                        {ITEMS.map((it, j) => {
                            return (
                                <Box key={j} {...it} >
                                    <Name color={it.nameColor}>
                                        {it.name}
                                    </Name>
                                    <Motto color={it.mottoColor}>
                                        {it.motto}
                                    </Motto>
                                    <SubMotto color={it.subMottoColor}>
                                        {it.subMotto}
                                    </SubMotto>
                                    <CenterText color={it.centerColor}>
                                        {it.centerText}
                                    </CenterText>
                                    <BottomText color={it.textColor}>
                                        <div dangerouslySetInnerHTML={{__html: it.text}}></div>
                                    </BottomText>
                                    <ButtonPlaceholder>
                                        <Button {...it} onClick={() => {
                                            Analytics.trackTariffClick(it.name);

                                            Analytics.send('lead.tariff_click', 'lead', () => {
                                            }, {
                                                event_label: it.paddle_id
                                            });
                                            try {
                                                window.localStorage.setItem('auth_mode', 'signup');
                                                window.location.href = `https://my.subtitles.love/?signup`;
                                            } catch (exc) {

                                            }
                                        }}>
                                            {it.buttonText}
                                        </Button>
                                    </ButtonPlaceholder>
                                </Box>
                            )
                        })}
                    </BoxesPlaceholder>

                </Cont>

            </MyContainer>

        </Wrapper>
    );
}

const Wrapper = styled.div`
      color: white;
      margin-top: 70px;
`;

const Cont = styled.div`
    padding-top: 48px;
    width: 920px;
    margin: 0 auto;
    box-sizing: border-box;
    @media(max-width: 920px){
      width: 100%;
    }
`;

const BoxesPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
`;

const Box = styled.div`
    padding: 25px;
    box-sizing: border-box;
    margin: 15px;
    width: calc(33.33% - 30px);
    border: 2px solid rgba(255, 255, 255, 0.03);
    border-radius: 16px;
    background: ${props => props.background};
    @media(max-width: 820px){
      width: calc(50% - 30px);
    }
    @media(max-width: 520px){
      width: calc(100% - 30px);
    }
`;

const Name = styled.div`
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 48px;
    text-align: center;
    letter-spacing: -1.25px;
    color: ${props => props.color};
`;

const Motto = styled.div`
    font-size: 32px;
    line-height: 48px;
    text-align: center;
    letter-spacing: -1.25px;
    color: ${props => props.color};
    margin-top: 10px;
    font-style: italic;
    font-weight: bold;
`;

const SubMotto = styled.div`
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    letter-spacing: 2.5px;
    color: ${props => props.color};
    margin-top: 6px;
`;

const CenterText = styled.div`
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 32px;
    text-align: center;
    letter-spacing: -0.25px;
    color: ${props => props.color};
    margin-top: 40px;
`;

const BottomText = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.25px;
    color: ${props => props.color};
    margin-top: 40px;
`;

const ButtonPlaceholder = styled.div`
    margin-top: 24px;
`;

const Button = styled.div`
    background: ${props => props.buttonColor};
    color: ${props => props.buttonTextColor};
    
    border-radius: 5px;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 17px;
    height: 56px;
    font-weight: bold;
    cursor: pointer;
    :hover{
      opacity: 0.8;
    }
`;

const ITEMS = [
    {
        id: '8oCk3S8ZJZ',
        paddle_id: '586033',

        background: '#212330',

        name: 'Free',
        nameColor: '#FFFFFF',
        motto: 'Free',
        mottoColor: '#5C5C5C',
        subMotto: 'FOREVER',
        subMottoColor: '#5C5C5C',
        centerText: '1 video',
        centerColor: '#EB5E4F',

        text: `
            <div class="feature_item" >
                Automatic Captions
            </div>
            <div class="feature_item" >
                Caption Text/Timing Editor
            </div>
            <div class="feature_item" >
                12+ Supported Languages
            </div>
            <div class="feature_item" >
                Simple Style editor
            </div>
            <div class="feature_item" >
                Video Resizing
            </div>
            <div class="feature_item" >
                
            </div>
        `,
        textColor: '#FFFCFC',

        buttonColor: '#01939B',
        buttonTextColor: '#FFFFFF',
        buttonText: 'SUBSCRIBE'
    },

    {

        id: 'EEySyPbAeu',
        paddle_id: '586001',

        background: '#212330',

        name: 'Medium',
        nameColor: '#FFFFFF',
        motto: '$13',
        mottoColor: '#5C5C5C',
        subMotto: 'PER MONTH',
        subMottoColor: '#5C5C5C',
        centerText: '10 videos',
        centerColor: '#EB5E4F',

        text: `
            <div class="feature_item" >
                Automatic Captions
            </div>
            <div class="feature_item" >
                Caption Text/Timing Editor
            </div>
            <div class="feature_item" >
                12+ Supported Languages
            </div>
            <div class="feature_item" >
                Advanced Style editor
            </div>
            <div class="feature_item" >
                Video Resizing
            </div>
            <div class="feature_item" >
                Download .SRT File
            </div>
        `,
        textColor: '#FFFCFC',

        buttonColor: '#01939B',
        buttonTextColor: '#FFFFFF',
        buttonText: 'SUBSCRIBE'
    },

    {
        id: '2v7DU1gu8l',
        paddle_id: '586002',

        background: '#FFAC30',

        name: 'Premium',
        nameColor: '#212330',
        motto: '$20',
        mottoColor: '#FFFFFF',
        subMotto: 'PER MONTH',
        subMottoColor: '#FFFFFF',

        centerText: '30 videos',
        centerColor: '#212330',

        text: `
            <div class="feature_item" >
                Automatic Captions
            </div>
            <div class="feature_item" >
                Caption Text/Timing Editor
            </div>
            <div class="feature_item" >
                12+ Supported Languages
            </div>
            <div class="feature_item" >
                Advanced Style editor
            </div>
            <div class="feature_item" >
                Video Resizing
            </div>
            <div class="feature_item" >
                Faster rendering
            </div>
            <div class="feature_item" >
                Download .SRT File
            </div>
        `,
        textColor: '#FFFFFF',

        buttonColor: '#FFFFFF',
        buttonTextColor: '#212330',
        buttonText: 'SUBSCRIBE'

    },

]

